import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import { SidebarMenu } from "./components";
import NotFound from "./pages/NotFound";

import { getItem } from "./services/localStorage.service";
import { isAuthenticatedRole } from "./services/roles.utils";
import { BENEFIT_ROLE } from "./constants/localStorage.constants";
import * as actionCreators from "./actions/app.actions";
import * as api from "./services/api/auth.service";
import { INTERCOM_API_KEY, OLVY_ORGANISATION } from "./constants/api.constants";
import { UserRolesKeysEnum } from "./constants/roles.constants";
import { shouldShowInRsd } from "./utils/transformTokensToRsd";
import { BulletinNotification } from "./components/BulletinNotification/BulletinNotification";
import { setupLocales } from "./utils/language/setupLocales";

const AppRoute = ({ component: Component, fullLayout, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (
          <>
            <BulletinNotification />
            {!fullLayout && <SidebarMenu />}
            <Component {...props} />
          </>
        );
      }}
    />
  );
};

const App = ({ routes, setUser, user }) => {
  const { companyId } = useSelector(state => state.app.user);

  const fetchUser = async () => {
    const response = await api.getUser();
    if (response.hasError) {
    } else {
      setUser(response);
      setupLocales(companyId);

      user.authenticatedRole &&
        !isAuthenticatedRole(user.authenticatedRole, UserRolesKeysEnum.ADMIN) &&
        window.Intercom("boot", {
          api_base: "https://api-iam.intercom.io",
          app_id: INTERCOM_API_KEY,
          name: `${response.firstName} ${response.lastName}`, // Full name
          email: response.email, // Email address
          user_id: response.id,
          remaining_tokens: response.remainingTokens,
          user_hash: response.userHash
        });

      isAuthenticatedRole(user.authenticatedRole, UserRolesKeysEnum.ADMIN) &&
        window.Intercom("shutdown");

      if (window.Olvy) {
        window.Olvy.init({
          organisation: OLVY_ORGANISATION,
          target: "#olvy-target",
          type: "sidebar",
          view: {
            showSearch: false,
            compact: false,
            showHeader: false,
            showUnreadIndicator: true,
            unreadIndicatorColor: "#cc1919",
            unreadIndicatorPosition: "top-right"
          }
        });
        window.Olvy.setUser({
          name: `${response.firstName} ${response.lastName}`, // Full name
          email: response.email, // Email address
          identifier: response.userHash
        });
      }
      return function cleanup() {
        if (window.Olvy) {
          window.Olvy.teardown();
        }
      };
    }
  };

  useEffect(() => {
    if (getItem(BENEFIT_ROLE)) {
      fetchUser();
    }
  }, [user.authenticatedRole.key]);

  const fetchCompanyTokensInfo = async companyId => {
    // Osmisliti bolju logiku
    if (!companyId) return;
    if (localStorage.getItem("isValueInRsd")) return;

    try {
      await shouldShowInRsd(companyId);
    } catch (error) {
      console.log(error, "Unable to set to currency");
    }
  };

  useEffect(() => {
    fetchCompanyTokensInfo(companyId);
  }, [companyId]);

  return (
    <Router>
      <Switch>
        {routes.map(({ exact, path, component, fullLayout }) => (
          <AppRoute
            exact={exact}
            path={path}
            component={component}
            key={path}
            fullLayout={fullLayout}
          />
        ))}
        <AppRoute component={NotFound} fullLayout={true} />
      </Switch>
    </Router>
  );
};

const mapStateToProps = state => {
  return {
    routes: state.app.routes,
    user: state.app.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setUser: payload => dispatch(actionCreators.setUser(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
